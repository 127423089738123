import generateIntersectionObserver from "../utility/generate-intersection-observer";
import CheckDevice from '../utility/check-device';

export default class CmnBrushAnimattion {
  constructor() {
    const cmnBrushAnimationElement = document.querySelectorAll('.js-brush-animation');

    if(CheckDevice.isFirefox()){
      for (let i = 0; i < cmnBrushAnimationElement.length; i++) {
        cmnBrushAnimationElement[i].style.opacity = '1.0';
      }
      return;
    }

    const brushImage = document.querySelector('body').dataset.brush;

    for (let i = 0; i < cmnBrushAnimationElement.length; i++) {
      let observerTarget;
      if (cmnBrushAnimationElement[i].hasAttribute('data-trigger-element')) {
        observerTarget = document.querySelector(cmnBrushAnimationElement[i].getAttribute('data-trigger-element'));
      } else {
        observerTarget = cmnBrushAnimationElement[i];
      }

      const delayTime = cmnBrushAnimationElement[i].getAttribute('data-delay-time') * 1000 || 0;
      const randNum = Math.floor((Math.random() + Math.floor(Math.random() * 9) + 1) * Math.pow(10, 8));
      const brushImagePath = brushImage + '?' + randNum;

      generateIntersectionObserver({
        targetElement: observerTarget,

        callback: ({ isIntersecting }) => {
          if (isIntersecting) {
            setTimeout(() => {
              gsap.set(cmnBrushAnimationElement[i], {
                opacity: 1,
                maskImage: `url(${brushImagePath})`,
                maskPosition: 'center',
                maskRepeat: 'no-repeat',
                maskSize: 'cover',
              });
              gsap.fromTo(cmnBrushAnimationElement[i],
                {
                  scale: 1.2
                },
                {
                  duration: 0.6,
                  ease: 'power1.out',
                  scale: 1.0
                })
            }, delayTime);
          }
        },
      }).observe(observerTarget);
    }
  }
}