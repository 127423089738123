export default class CmnBanner {
  constructor(){
    const banner = document.querySelector('.cmn-banner');
    const closeBtn = document.querySelector('.cmn-banner-close-btn');
    const showBtn = document.querySelector('.cmn-banner-show-btn');

    if(!banner) return

    closeBtn.addEventListener('click',()=>{
      banner.style.visibility = 'hidden';
      showBtn.style.visibility = 'visible';
    });

    showBtn.addEventListener('click',()=>{
      banner.style.visibility = 'visible';
      showBtn.style.visibility = 'hidden';
    });
  }
}