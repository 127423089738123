export default class TextSpanEnclose {
  constructor(element) {
    const HTML = element.innerHTML;
    const pattern = /(<[^>]*>)/;
    const split = HTML.split(pattern);
    let spanArray = [];
    element.innerHTML = '';
    for (let i = 0; i < split.length; i++) {
      if (split[i].search(pattern) !== -1) {
        // HTMLタグの場合、そのまま挿入
        element.insertAdjacentHTML('beforeend', split[i]);

      } else {

        // 一文字づつ配列
        const txtSplit = split[i].split('');

        for (let j = 0; j < txtSplit.length; j++) {
          const span = document.createElement('span');

          // 空白はinline
          span.style.display = (txtSplit[j] === ' ') ? 'inline' : 'inline-block';

          span.textContent = txtSplit[j];

          element.appendChild(span);

          spanArray.push(span);
        }
      }
    }
    return spanArray;
  }
}