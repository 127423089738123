import CheckDevice from '../utility/check-device';

const $hamburger = document.getElementById('hamburger');
const $headerMenu = document.getElementById('header-menu');
const $body = document.getElementById('body');
const $mediaQueryList = window.matchMedia("(max-width:768px)");

let $scrollPosition = 0;

const $headerMain = document.getElementById('header__main');
const $headerHeight = $headerMain.clientHeight;

export default class Menu {
    constructor() {

        //ハンバーガーメニュー開いている時のbodyのスクロール阻止
        const bodyFixedOn = () => {
            if(CheckDevice.isIos()){
                $scrollPosition = window.pageYOffset;
                $body.style.position = 'fixed';
                $body.style.top = '-${$scrollPosition}px';
            }else {
                $body.style.overflow = 'hidden';
            }
        }
        
        const bodyFixedOff = () => {
            if(CheckDevice.isIos()){
                $body.style.removeProperty('position');
                $body.style.removeProperty('top');
                window.scrollTo(0, $scrollPosition);
            }else {
                $body.style.removeProperty('overflow');
            }
        }
        
        //ハンバーガーボタンクリックでメニュー表示非表示
        $hamburger.addEventListener('click',(e)=>{
            if ($headerMenu.classList.contains('is-open')) {
                $headerMenu.classList.remove('is-open');
                $hamburger.classList.remove('is-open');
                $body.classList.remove('is-open');
                bodyFixedOff();
            } else {
                $headerMenu.classList.add('is-open');
                $hamburger.classList.add('is-open');
                $body.classList.add('is-open');
                bodyFixedOn();
            }
            e.preventDefault();
        });

        //ウィンドウ幅広げた際にハンバーガーメニュー非表示
        const listener = (event) => {
            if (!event.matches) {
                $headerMenu.classList.remove('is-open');
                $hamburger.classList.remove('is-open');
                $body.classList.remove('is-open');
                bodyFixedOff();
            }
        };

        $mediaQueryList.addEventListener("change", listener);
        listener($mediaQueryList);

        //トップからスクロールするとヘッダーの高さ変わる
        window.addEventListener('scroll',() => {
            if ($body.scrollTop > $headerHeight || document.documentElement.scrollTop > $headerHeight) {
                $headerMain.classList.remove('is-top');
            } else {
                $headerMain.classList.add('is-top');
            }
        })
    }
}