import PageTransform from './common/page-transform';
import TextSpanEnclose from './common/text-span-encode';
import CmnBanner from './common/cmn-banner';
import CmnBrushAnimation from './common/cmn-brush-animation';
import CmnParallax from './common/cmn-parallax';
import Menu from './common/menu';
import SmoothScroll from './common/smooth-scroll';

export default class Common {
    constructor() {
        new PageTransform();
        const textSpanEncloseElement = document.querySelectorAll('.js-span-enclose');
        for (let i = 0; i < textSpanEncloseElement.length; i++) {
            new TextSpanEnclose(textSpanEncloseElement[i]);
        }
        const parallaxTarget = document.querySelectorAll('.js-parallax');
        for (let i = 0; i < parallaxTarget.length; i++) {
            new CmnParallax(parallaxTarget[i]);
        }
        new CmnBanner();
        new CmnBrushAnimation();
        new SmoothScroll().clickEvent();
        new Menu();
    }
}

new Common();