import PageTransformCore from "./page-transform-core";
import CheckDevice from '../utility/check-device';

export default class PageTransform {
  constructor() {
    this.$cmnPageTransformCover = document.querySelector('.cmn-load-cover');
    this.$cmnPageTransformBrush = document.querySelector('.cmn-load-brush');

    if(CheckDevice.isFirefox()){
      this.$cmnPageTransformCover.style.display = 'none';
      this.$cmnPageTransformBrush.style.display = 'none';
      return;
    }

    this.pageTransform = new PageTransformCore();
    this.brushImage = document.querySelector('body').dataset.brush;
    this.brushImageReverse = document.querySelector('body').dataset.brushReverse;

    const randNum = Math.floor((Math.random() + Math.floor(Math.random() * 9) + 1) * Math.pow(10, 8));
    const brushImagePath = this.brushImage + '?' + randNum;
    const brushImageReversePath = this.brushImageReverse + '?' + randNum;

    this.pageTransform.on('BEFORE_TRANSFORM', () => {
      this.$cmnPageTransformCover.style.pointerEvents = 'auto';

      gsap.set(this.$cmnPageTransformBrush, {
        opacity: 1,
        maskImage: `url(${brushImagePath})`,
        maskPosition: 'center',
        maskRepeat: 'no-repeat',
        maskSize: 'cover',
      });
    });

    window.addEventListener('load', () => {
      const maskImage = new Image();
      maskImage.src = brushImageReversePath;

      maskImage.addEventListener('load', () => {
        window.GlobalEvent.emit('OPENNING_END')

        gsap.set(this.$cmnPageTransformBrush, {
          maskImage: `url(${brushImageReversePath})`,
          maskPosition: 'center',
          maskRepeat: 'no-repeat',
          maskSize: 'cover',
        });

        setTimeout(() => {
          gsap.set(this.$cmnPageTransformCover, {
            opacity: 0,
          });
        }, 150);
      });
    });


    // safariでブラウザバックで残るときがあるので消す
    window.addEventListener('pageshow', (e) => {
      // キャッシュ有効時
      if (e.persisted) {
        location.reload();
      }
    });
  }
}