import CheckDevice from '../utility/check-device';

export default class PageTransformCore extends EventEmitter {
  constructor() {
    super();
    this.$link = document.querySelectorAll('a');
    this.isKey = false;

    for (let i = 0; i < this.$link.length; i++) {
      this.$link[i].addEventListener('click', (e) => {
        const currentTarget = e.currentTarget;
        const href = currentTarget.getAttribute('href');
        const flag = (this.isKey) ||
          (href.indexOf('#') !== -1) ||
          (href.indexOf('mailto') !== -1) ||
          (href.indexOf('tel') !== -1) ||
          (href.indexOf('.pdf') !== -1) ||
          (currentTarget.getAttribute('target') !== null) ||
          (currentTarget.hasAttribute('data-normal-transition'));
        if (flag) return;
        e.preventDefault();

        this.emit('BEFORE_TRANSFORM');

        setTimeout(() => {
          location.href = href;
        }, CheckDevice.isSafari() ? 1200 : 400);
      }, { passive: false });
    }

    window.addEventListener('keydown', () => {
      this.isKey = true;
    });

    window.addEventListener('keyup', () => {
      this.isKey = false;
    });
  }
}